import "core-js/modules/es.array.push.js";
export var useTabsStore = defineStore('tabs', {
  state: function () {
    return {
      visitedRoutes: []
    };
  },
  getters: {
    getVisitedRoutes: function (state) {
      return state.visitedRoutes.filter(function (route) {
        return route.name !== 'Login';
      });
    }
  },
  actions: {
    /**
     * @description 添加标签页
     * @param {*} route
     * @returns
     */
    addVisitedRoute: function (route) {
      var target = this.visitedRoutes.find(function (item) {
        return item.path === route.path;
      });
      if (target && !route.meta.dynamicNewTab) Object.assign(target, route);else if (!target) this.visitedRoutes.push(Object.assign({}, route));
      //应对极特殊情况：没有配置noClosable的情况，默认使当前tab不可关闭
      if (!this.visitedRoutes.find(function (route) {
        return route.meta.noClosable;
      })) this.visitedRoutes[0].meta.noClosable = true;
    },
    /**
     * @description 删除当前标签页
     * @param {*} path
     * @returns
     */
    delVisitedRoute: function (path) {
      this.visitedRoutes = this.visitedRoutes.filter(function (route) {
        return route.path !== path;
      });
    },
    /**
     * @description 删除当前标签页以外其它全部标签页
     * @param {*} path
     * @returns
     */
    delOthersVisitedRoutes: function (path) {
      this.visitedRoutes = this.visitedRoutes.filter(function (route) {
        return route.meta.noClosable || route.path === path;
      });
    },
    /**
     * @description 删除当前标签页左边全部标签页
     * @param {*} path
     * @returns
     */
    delLeftVisitedRoutes: function (path) {
      var found = false;
      this.visitedRoutes = this.visitedRoutes.filter(function (route) {
        if (route.path === path) found = true;
        return route.meta.noClosable || found;
      });
    },
    /**
     * @description 删除当前标签页右边全部标签页
     * @param {*} path
     * @returns
     */
    delRightVisitedRoutes: function (path) {
      var found = false;
      this.visitedRoutes = this.visitedRoutes.filter(function (route) {
        var close = found;
        if (route.path === path) found = true;
        return route.meta.noClosable || !close;
      });
    },
    /**
     * @description 删除全部标签页
     * @returns
     */
    delAllVisitedRoutes: function () {
      this.visitedRoutes = this.visitedRoutes.filter(function (route) {
        return route.meta.noClosable;
      });
    },
    /**
     * @description 修改 meta
     * @param options
     */
    changeTabsMeta: function (options) {
      function handleVisitedRoutes(visitedRoutes) {
        return visitedRoutes.map(function (route) {
          if (route.name === options.name || route.meta.title === options.title) Object.assign(route.meta, options.meta);
          if (route.children && route.children.length) route.children = handleVisitedRoutes(route.children);
          return route;
        });
      }
      this.visitedRoutes = handleVisitedRoutes(this.visitedRoutes);
    }
  }
});