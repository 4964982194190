import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(function (_a) {
      var Component = _a.Component;
      return [_createVNode(_Transition, {
        mode: "out-in",
        name: $setup.theme.showPageTransition ? 'fade-transform' : 'no-transform'
      }, {
        default: _withCtx(function () {
          return [(_openBlock(), _createBlock(_KeepAlive, {
            include: $setup.keepAliveNameList,
            max: $setup.keepAliveMaxNum
          }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: $setup.routerKey,
            ref: "componentRef"
          }))], 1032 /* PROPS, DYNAMIC_SLOTS */, ["include", "max"]))];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"])];
    }),
    _: 1 /* STABLE */
  });
}