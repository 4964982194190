/*
 * @Author: yk huo_cai@126.com
 * @Date: 2022-04-16 16:59:58
 * @LastEditors: yk huo_cai@126.com
 * @LastEditTime: 2022-06-07 13:41:47
 * @FilePath: \jssclubetc-pc-vue\src\api\user.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: yk huo_cai@126.com
 * @Date: 2022-04-16 16:59:58
 * @LastEditors: yk huo_cai@126.com
 * @LastEditTime: 2022-06-01 16:50:08
 * @FilePath: \jssclubetc-pc-vue\src\api\user.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
import qs from 'qs'
export async function login(data: any) {
  return request({
    url: '/account/login',
    method: 'post',
    data,
  })
}

export async function socialLogin(data: any) {
  return request({
    url: '/socialLogin',
    method: 'post',
    data,
  })
}

export function getUserInfo() {
  return request({
    url: '/user/getUserInfo',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/account/logout',
    method: 'post',
  })
}

export function register(data: any) {
  return request({
    url: '/register',
    method: 'post',
    data,
  })
}
//行程列表
export function getTripList(data: any) {
  return request({
    url: '/trip/list?' + qs.stringify(data),
    method: 'get',
  })
}
//行程详情
export function getTripInfo(data: any) {
  return request({
    url: '/trip/info?' + qs.stringify(data),
    method: 'get',
  })
}
//行程详情
export function getPayFailList(data: any) {
  return request({
    url: '/trip/getPayFailList?' + qs.stringify(data),
    method: 'get',
  })
}
//用户列表
export function getUserList(data: any) {
  return request({
    url: '/user/list?' + qs.stringify(data),
    method: 'get',
  })
}
//用户详情
export function getUserDetail(data: any) {
  return request({
    url: '/user/detail?' + qs.stringify(data),
    method: 'get',
  })
}
//新增用户
export function addUser(data: any) {
  return request({
    url: '/user/add',
    method: 'post',
    data,
  })
}
//更新用户
export function updateUser(data: any) {
  return request({
    url: '/user/update',
    method: 'post',
    data,
  })
}
//删除用户
export function deleteUser(data: any) {
  return request({
    url: '/user/del',
    method: 'post',
    data,
  })
}
//修改密码
export function changePwd(data: any) {
  return request({
    url: '/user/changePwd',
    method: 'post',
    data,
  })
}
//用户END
